@font-face {
	font-family: 'FjallaOne';
	src: url('./fonts/FjallaOne-Regular.eot');
	src: url('./fonts/FjallaOne-Regular.eot?#iefix') format('embedded-opentype'),
			url('./fonts/FjallaOne-Regular.woff2') format('woff2'),
			url('./fonts/FjallaOne-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('./fonts/Oswald-Light.eot');
	src: url('./fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
			url('./fonts/Oswald-Light.woff2') format('woff2'),
			url('./fonts/Oswald-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('./fonts/Oswald-Light.eot');
	src: url('./fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
			url('./fonts/Oswald-Light.woff2') format('woff2'),
			url('./fonts/Oswald-Light.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('./fonts/Oswald-Regular.eot');
	src: url('./fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
			url('./fonts/Oswald-Regular.woff2') format('woff2'),
			url('./fonts/Oswald-Regular.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('./fonts/Oswald-SemiBold.eot');
	src: url('./fonts/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
			url('./fonts/Oswald-SemiBold.woff2') format('woff2'),
			url('./fonts/Oswald-SemiBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}